import "leaflet.vectorgrid";
import { useEffect, useRef } from "react";
import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";
import PropTypes from "prop-types";

export default function EmissionsLayer(props) {
    const { map } = useLeafletContext();
    const firstUpdate = useRef(true);

    function getEmissionFeatureId(properties) {
        return `${properties.z}:${properties.x}:${properties.y}`;
    }

    function getEmissionFeatureStyle(properties, selected) {
        return {
            weight: selected ? 4 : 0,
            color: "#CCC",
            fillColor: properties.color,
            fillOpacity: 0.8,
            fill: true,
        };
    }

    let emissionsLayer = L.vectorGrid.protobuf(`${props.apiUrl}?period=${props.period}&version=${props.version}`, {
        vectorTileLayerStyles: {
            emissions: function (properties, zoom, geometryDimension) {
                return getEmissionFeatureStyle(properties, false);
            },
        },
        interactive: true,
        getFeatureId: function (f) {
            return getEmissionFeatureId(f.properties);
        },
    });

    emissionsLayer.on("click", function (e) {
        props.onClick(e.layer.properties);
    });

    emissionsLayer.on("mouseover", function (e) {
        emissionsLayer.setFeatureStyle(getEmissionFeatureId(e.layer.properties), getEmissionFeatureStyle(e.layer.properties, true));
        props.onMouseOver(e.layer.properties);
        props.onClick();
    });

    emissionsLayer.on("mouseout", function (e) {
        emissionsLayer.setFeatureStyle(getEmissionFeatureId(e.layer.properties), getEmissionFeatureStyle(e.layer.properties, false));
        props.onMouseOut();
        props.onClick();
    });

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else {
            map.removeLayer(emissionsLayer);
        }
        map.addLayer(emissionsLayer);

        return () => {
            map.removeLayer(emissionsLayer); // Executed during component unmount
        };
    }, [props.period, props.color]); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
}

EmissionsLayer.propTypes = {
    period: PropTypes.string.isRequired,
    apiUrl: PropTypes.string.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    onMouseOut: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    version: PropTypes.string.isRequired,
};
