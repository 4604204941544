import "./App.css";
import "leaflet/dist/leaflet.css";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Legend } from "chart.js";
import { MapContainer, TileLayer, ZoomControl, useMapEvents } from "react-leaflet";
import { useState, useEffect } from "react";
import axios from "axios";
import EmissionsLayer from "./EmissionsLayer";
import PropTypes from "prop-types";

const PERIODS = {
    yesterday: "Yesterday",
    last_week: "Last week",
    last_month: "Last month",
    last_year: "Last year",
};
const DEFAULT_ZOOM_LEVEL = 3;
const TILE_SCALING = { 2: 7, 3: 8, 4: 9, 5: 9, 6: 10, 7: 10, 8: 10, 9: 10, 10: 10 };

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

function yAxisFormatter(label, index, labels) {
    return label > 1000 ? (label / 1000).toString() + "K" : label.toString();
}

function ZoomLevel(props) {
    useMapEvents({
        zoom(e) {
            props.setZoomLevel(e.target.getZoom());
        },
    });
    return null;
}

const chartOptions = {
    scales: {
        y: {
            ticks: {
                callback: yAxisFormatter,
            },
        },
        x: {
            ticks: {
                maxRotation: 90,
                minRotation: 90,
            },
        },
    },
};

export default function App(props) {
    const [tileProperties, setTileProperties] = useState();
    const [period, setPeriod] = useState("yesterday");
    const [tileSelected, setTileSelected] = useState();
    const [cachedTileEmissions, setCachedTileEmissions] = useState({});
    const [selectedTileEmissions, setSelectedTileEmissions] = useState();
    const [selectedTileEmissionsLoading, setSelectedTileEmissionsLoading] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(DEFAULT_ZOOM_LEVEL);
    const [globalEmissions, setGlobalEmissions] = useState();
    const [legendOptions, setLegenOptions] = useState([]);
    const [infoOpen, setInfoOpen] = useState(false);
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [feedbackSubject, setFeedbackSubject] = useState("");
    const [feedbackMessage, setFeedbackMessage] = useState("");
    let chartData;

    const co2tonnesPerKm2 = tileProperties ? (parseFloat(tileProperties.co2_tonnes) / parseFloat(tileProperties.area_km2)).toFixed(3) : null;

    if (selectedTileEmissions) {
        chartData = {
            labels: selectedTileEmissions.map((emission) => emission.period),
            datasets: [
                {
                    label: "CO₂ emissions (in tonnes)",
                    data: selectedTileEmissions.map((emission) => emission.co2_tonnes),
                    backgroundColor: "rgba(128, 128, 128, 1)",
                },
            ],
        };
    }

    useEffect(() => {
        setGlobalEmissions();
        axios.get(`${props.apiUrl}/legend-options`).then((res) => {
            setLegenOptions(res.data);
        });
    }, [period]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSelectedTileEmissions();
        if (tileProperties && tileSelected) {
            let cacheKey = `${period}:${tileProperties.z}:${tileProperties.x}:${tileProperties.y}`;

            if (cacheKey in cachedTileEmissions) {
                setSelectedTileEmissions(cachedTileEmissions[cacheKey]);
            } else {
                setSelectedTileEmissionsLoading(true);
                axios
                    .get(`${props.apiUrl}/emissions/${tileProperties.z}/${tileProperties.x}/${tileProperties.y}`, {
                        params: {
                            period: period,
                            version: props.version,
                        },
                    })
                    .then((res) => {
                        let updatedCachedTileEmissions = Object.assign(cachedTileEmissions, { [cacheKey]: res.data });
                        setCachedTileEmissions(updatedCachedTileEmissions);
                        setSelectedTileEmissions(res.data);
                        setSelectedTileEmissionsLoading(false);
                    });
            }
        }
    }, [tileProperties, tileSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setGlobalEmissions();
        axios
            .get(`${props.apiUrl}/emissions`, {
                params: {
                    period: period,
                    version: props.version,
                },
            })
            .then((res) => {
                setGlobalEmissions(res.data.co2_tonnes);
            });
    }, [period]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <MapContainer center={[29, 19]} zoom={DEFAULT_ZOOM_LEVEL} zoomControl={false} minZoom={2} maxZoom={10}>
            <TileLayer url={props.mapboxUrl} />
            <EmissionsLayer
                apiUrl={`${props.apiUrl}/emission-tiles/{z}/{x}/{y}`}
                period={period}
                onMouseOver={setTileProperties}
                onMouseOut={setTileProperties}
                onClick={setTileSelected}
                version={props.version}
            />
            <ZoomLevel setZoomLevel={setZoomLevel} />
            <ZoomControl position="bottomleft" />
            {props.debug && (
                <div className="leaflet-top leaflet-right">
                    <div className="debug-info info leaflet-control leaflet-bar">
                        Current zoom level: {zoomLevel}
                        <br />
                        Current tile size: {TILE_SCALING[zoomLevel]}
                    </div>
                </div>
            )}
            <div className="leaflet-top leaflet-right">
                <div className="info leaflet-control leaflet-bar info-button">
                    <div style={{ textAlign: "right" }}>
                        <button
                            onClick={() => {
                                if (infoOpen) {
                                    setFeedbackOpen(false);
                                }
                                setInfoOpen(!infoOpen);
                            }}
                        >
                            ?
                        </button>
                    </div>
                    {infoOpen && (
                        <>
                            <div style={{ whiteSpace: "nowrap" }}>
                                In September 2021, the{" "}
                                <a
                                    href="https://www.wartsila.com/media/news/23-09-2020-wartsila-ranked-first-in-un-challenge-to-fight-climate-change-with-big-data-2786975"
                                    style={{ display: "inline" }}
                                >
                                    Wärtsilä team Blue Carbon
                                </a>{" "}
                                won the{" "}
                                <a href="https://www.unglobalpulse.org/2020/10/un-hosts-first-ais-big-data-hackathon/" style={{ display: "inline" }}>
                                    UN AIS Big Data Hackathon
                                </a>
                                .
                                <br />
                                That map showing the geographical distribution of CO2 shipping emissions is now refreshed daily.
                                <br />
                            </div>
                            {!feedbackOpen && (
                                <div style={{ textAlign: "right", marginTop: "20px" }}>
                                    <button onClick={() => setFeedbackOpen(true)}>Feedback</button>
                                </div>
                            )}
                            {feedbackOpen && (
                                <div style={{ textAlign: "right" }}>
                                    <input
                                        type="text"
                                        placeholder="Subject.."
                                        style={{ marginTop: "20px", width: "50%" }}
                                        value={feedbackSubject}
                                        onChange={(event) => setFeedbackSubject(event.target.value)}
                                    />
                                    <textarea
                                        placeholder="Message.."
                                        style={{ resize: "none", marginTop: "20px", width: "50%" }}
                                        value={feedbackMessage}
                                        onChange={(event) => setFeedbackMessage(event.target.value)}
                                    />
                                    <div>
                                        <button
                                            style={{ resize: "none", marginTop: "20px" }}
                                            onClick={() => {
                                                alert(`${feedbackSubject} - ${feedbackMessage}`);
                                                setFeedbackOpen(false);
                                                setFeedbackSubject("");
                                                setFeedbackMessage("");
                                            }}
                                            disabled={!feedbackSubject || !feedbackMessage}
                                        >
                                            Send
                                        </button>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className="leaflet-top leaflet-left">
                <div className="info leaflet-control leaflet-bar">
                    When?&nbsp;&nbsp;
                    <select value={period} onChange={(e) => setPeriod(e.target.value)}>
                        {Object.keys(PERIODS).map((period) => (
                            <option key={period} value={period}>
                                {PERIODS[period]}
                            </option>
                        ))}
                    </select>
                    <br />
                    <br />
                    {globalEmissions && <div>CO₂ emissions: {globalEmissions.toLocaleString("en-US", { maximumSignificantDigits: 3 })} t global</div>}
                    {tileProperties && (
                        <>
                            <br />
                            <br />
                            CO₂ emissions / day: {tileProperties.co2_tonnes} t<br />
                            CO₂ emissions / km² / day: {co2tonnesPerKm2} t<br />
                            Area: {tileProperties.area_km2} km²
                            <br />
                            {!selectedTileEmissions && !tileSelected && (
                                <>
                                    <br />
                                    <i>
                                        Click the tile to load
                                        <br />
                                        detailed CO₂ emissions
                                        <br />
                                        for the selected period
                                    </i>
                                </>
                            )}
                        </>
                    )}
                    {tileProperties && tileSelected && (
                        <>
                            <br />
                            {selectedTileEmissions && <Bar width={200} height={200} options={chartOptions} data={chartData} />}
                            {selectedTileEmissionsLoading && "Loading data.."}
                        </>
                    )}
                </div>
            </div>
            {legendOptions.length && (
                <div className="leaflet-bottom leaflet-right legend">
                    <div className="info legend leaflet-control leaflet-bar">
                        <h4>t CO₂ / km² / day</h4>
                        {legendOptions.map((legendOption) => (
                            <>
                                <i style={{ background: legendOption.color, opacity: 0.8 }} />
                                {legendOption.interval}
                                <br />
                            </>
                        ))}
                    </div>
                </div>
            )}
        </MapContainer>
    );
}

App.propTypes = {
    apiUrl: PropTypes.string.isRequired,
    mapboxUrl: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    debug: PropTypes.bool,
};

ZoomLevel.propTypes = {
    setZoomLevel: PropTypes.func.isRequired,
};
