import "./index.css";
import { createRoot } from "react-dom/client";
import { datadogRum } from "@datadog/browser-rum";
import App from "./App";
import axios from "axios";
import React from "react";
import reportWebVitals from "./reportWebVitals";

axios.get("/config.json").then((res) => {
    datadogRum.init({
        applicationId: res.data.DATADOG_RUM_APPLICATION_ID,
        clientToken: res.data.DATADOG_RUM_CLIENT_TOKEN,
        site: "datadoghq.eu",
        service: "emissions-map",
        env: res.data.REACT_APP_ENVIRONMENT,
        version: res.data.REACT_APP_VERSION,
        sampleRate: 100, // 100% == track all users
        trackInteractions: false,
    });

    createRoot(document.getElementById("root")).render(
        <React.StrictMode>
            <App mapboxUrl={res.data.MAPBOX_URL} apiUrl={res.data.API_URL} version={res.data.VERSION} debug={res.data.DEBUG_INFO} />
        </React.StrictMode>,
    );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
